import React from "react"
import { Box } from "grommet"
import { Layout, SEO, ErrorInfo, FormattedMessage } from "../../components"

const Page = () => {
  return (
    <Layout>
      <SEO title="Home" />
      <Box pad="medium">
        <ErrorInfo>
          <FormattedMessage
            id="hello"
            defaultMessage="Hello"
          ></FormattedMessage>
        </ErrorInfo>
      </Box>
    </Layout>
  )
}

export default Page
